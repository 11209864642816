@import "_fonts";
$font: 'Golos', sans-serif;
$black: #2C2D2E;
$white: #fff;
$light: #F3F3F5;
$gray-20: #F9F9FA;
$gray-60: #ECEDF0;
$text-gray: #919399;
$border: rgba(0, 16, 61, 0.12);
$red: #ED0A34;
$green: #0DC268;
$main: #2ED3B7; //#2ED3B7
$main-dark: #107569;
$bg-green: #134E48;

@mixin size($height, $width: false) {
  height: #{$height}rem;
  @if $width {
    width: #{$width}rem;
  } @else {
    width: #{$height}rem;
  }
}
@mixin fnt($size: 16, $height: 24, $color: $black, $weight: 400, $font: $font) {
  font-family: $font;
  font-style: normal;
  font-weight: $weight;
  font-variation-settings: "wght" $weight;
  font-size: #{$size}rem;
  line-height: #{$height}rem;
  color: $color;
}

@mixin btn($bg-color: $main, $color: #fff) {
  @include fnt(14, 16, $color  !important, 500);
  display: inline-block;
  padding: 8rem 16rem;
  background-color: $bg-color;
  border-radius: 4rem;
  text-align: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  border: none;
  &:hover {
    text-decoration: none;
    color: $color !important;
    background-color: darken($bg-color, 5%);
  }
}
