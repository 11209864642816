@font-face {
  font-family: 'Golos';
  src:
          url('../../public/fonts/Golos/golos-text_regular.woff2') format('woff2'),
          url('../../public/fonts/Golos/golos-text_regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src:
          url('../../public/fonts/Golos/golos-text_medium.woff2') format('woff2'),
          url('../../public/fonts/Golos/golos-text_medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src:
          url('../../public/fonts/Golos/golos-text_demibold.woff2') format('woff2'),
          url('../../public/fonts/Golos/golos-text_demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Golos';
  src:
          url('../../public/fonts/Golos/golos-text_bold.woff2') format('woff2'),
          url('../../public/fonts/Golos/golos-text_bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}