
/*SM*/
@media screen and (max-width: 1024px) {
  .main-content .benefit-grid {
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    grid-gap: 32rem;
  }
  .main-content .text-img-grid{
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    margin-bottom: 72rem;
  }
  .main-content .text-img-grid .img-block{
    aspect-ratio: 932/262;
    border-radius: 0 0 24rem 24rem;
  }
  .main-content .text-img-grid .text-block{
    border-radius: 24rem 24rem 0 0;
  }
  .main-content .booking{
    max-width: 724rem;
  }
}

@media screen and (max-width: 800px) {
  .main-content .gallery-grid{
    grid-template-columns: 100%;
    grid-auto-columns: auto;
  }
  .container_center{
    padding: 0 24rem;
  }
  .footer .top{
    padding: 20rem 0;
  }
  .footer .bottom{
    padding: 4rem 0;
  }
}


/*XS*/
@media screen and (max-width: 600px) {
  .preview-block_content .preview-block_top-text{
    font-size: 16rem;
    line-height: 24rem;
  }
  .preview-block_content .preview-block_top-text:before, .preview-block_content .preview-block_top-text:after{
    width: 150rem;
  }
  h1{
    font-size: 64rem;
    line-height: 64rem;
  }
  .preview-block_content .preview-block_subtitle{
    font-size: 24rem;
    line-height: 32rem;
  }
  .preview-block_header .phone{
    font-size: 20rem;
    line-height: 32rem;
    margin-right: 16rem;
  }
  .main-content .text-img-grid .text-block{
    padding: 48rem 32rem;
  }
  .main-content .booking-title{
    font-size: 20rem;
    line-height: 28rem;
  }
  .main-content .form-block{
    padding: 32rem;
  }
  .footer-contacts{
    display: flex;
    flex-direction: column;
    .phone{
      margin-right: 0rem;
      margin-bottom: 8rem;
    }
    .bik-link{
      text-align: center;
    }
  }
  .main-content .booking{
    aspect-ratio: unset;
  }
  .main-content .form-block{
    border-radius: 0 0 16rem 16rem;
  }
}


@media screen and (max-width: 500px) {

}

@media screen and (max-width: 400px) {
  .preview-block .logo-img{
    max-width: 152rem;
  }
  .container_center{
    padding: 0 16rem;
  }
  .header-contacts{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .preview-block_header .phone{
    font-size: 16rem;
    line-height: 24rem;
    margin-bottom: 8rem;
    margin-right: 0;
  }
  .preview-block_header .consult-btn{
    font-size: 12rem;
    line-height: 20rem;
    padding: 8rem 12rem;
    border-radius: 8rem;
  }
  .preview-block_content .preview-block_top-text:before, .preview-block_content .preview-block_top-text:after{
    width: 72rem;
  }
  .preview-block_content .preview-block_subtitle{
    font-size: 20rem;
    line-height: 32rem;
  }
  .location-block{
    flex-direction: column;
  }
  .preview-block_content .location._first{
    margin-right: 0rem;
    margin-bottom: 12rem;
  }
  .preview-block_content .location{
    text-align: center;
  }
  .main-content .page-title{
    font-size: 24rem;
    line-height: 32rem;
  }
  .main-content .text-img-grid .text-block{
    padding: 32rem 24rem;
  }
  .main-content .page-text{
    font-size: 12rem;
    line-height: 24rem;
  }

  .main-content .booking-title{
    text-align: center;
  }
  .main-content .form-block{
    //border-radius: 0 0 16rem 16rem;
    padding: 32rem 16rem;
  }
  .main-content .form-block .form-title{
    font-size: 24rem;
    line-height: 32rem;
  }
  .main-content .form-block .form-subtitle{
    font-size: 14rem;
    line-height: 24rem;
  }
  .main-content .form-block .consultation-form{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .main-content .form-block .consultation-form .phone-input{
    width: 100%;
    margin-right: 0rem;
    margin-bottom: 12rem;
  }
  .main-content .form-block .consultation-form .send-form_btn{
    width: 100%;
    text-align: center;
  }
  .footer-logo{
    flex-direction: column;
  }
  .footer .bik-text{
    margin-left: 0rem;
    max-width: 149rem;
    margin-top: 8rem;
  }
  .footer-contacts .phone{
    font-size: 16rem;
    line-height: 20rem;
    margin-right: 0rem;
    margin-bottom: 8rem;
  }
  .main-content .text-img-grid .img-block{
    aspect-ratio: unset;
  }
  .main-content .text-img-grid{
    grid-auto-rows: 2fr 1fr;
  }
  .main-content .booking-subtitle{
    padding: 8rem 16rem;
  }

}

