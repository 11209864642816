@import "_variables";

.preview-block{
  width: 100%;
  height: 640rem;
  background-image: url(../img/white_field_bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 32rem;
  margin-bottom: 64rem;
  .logo-img{
    width: 100%;
    height: auto;
    max-width: 185rem;
  }
  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 102rem;
    .phone {
      @include fnt(24, 32, white, 600);
      margin-right: 24rem;
      //text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 0px 16px #5FE9D0;
    }
    .consult-btn {
      background: $main;
      box-shadow: 4rem 4rem 0rem rgba(255, 255, 255, 0.8);
      border-radius: 12rem;
      padding: 12rem 16rem;
      @include fnt(16, 24, white, 600);
      &:hover {
        text-decoration: none;
      }
    }
  }
  &_content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .preview-block_top-text{
      @include fnt(20, 28, white, 400);
      display: flex;
      align-items: center;
      margin-bottom: 8rem;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 0px 16px #77D1D9;
      &:before,
      &:after {
        content: "";
        display: block;
        height: 2px;
        width: 199rem;
        background: white;
      }
      &:before{
        margin-right: 10rem;
      }
      &:after{
        margin-left: 10rem;
      }
    }
    .preview-block_subtitle{
      @include fnt(32, 48, white, 500);
      margin: 8rem 0 40rem 0;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 32px #77D1D9;
    }
    .review-block_border-rows{
      border-top: 2px solid white;
      border-bottom: 2px solid white;
      margin-bottom: 40rem;
    }
    .preview-block_text{
      @include fnt(16, 24, white, 500);
      margin: 4rem 24rem;
    }
    .location{
      @include fnt(16, 24, white, 500);
      background: $main;
      border-radius: 16px;
      padding: 0 12rem;
      &._first{
        margin-right: 8rem;
      }
    }
  }
}
.container_center{
  max-width: 1440rem;
  padding: 0 64rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.main-content{
  .benefit-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 32rem;
    margin-bottom: 48rem;
    .benefit-card{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #ECEDF0;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 24px;
      padding: 24rem;
      width: 100%;
      background: url(../img/Circles.svg) no-repeat right bottom;
      background-size: 160rem;
      &_title{
        @include fnt(16, 24, $black, 600);
        margin-bottom: 8rem;
        text-align: center;
      }
      &_text{
        @include fnt(14, 20, $black, 400);
        text-align: center;
      }
      &_icon{
        background-color: $main;
        box-shadow: 0px 4px 8px rgba(44, 45, 46, 0.15);
        border-radius: 12rem;
        padding: 12rem;
        display: block;
        @include size(48);
        margin-bottom: 8rem;
        &._bus,
        &._factory,
        &._park{
          background-repeat: no-repeat;
          background-position: center;
          background-size: 24rem;
        }
        &._bus{
          background-image: url(../img/bus.svg);
        }
        &._factory{
          background-image: url(../img/factory.svg);
        }
        &._park{
          background-image: url(../img/park.svg);
        }
      }
    }
  }
  .gray-text{
    @include fnt(14, 24, #BFC1C7, 400);
    text-align: center;
    margin-bottom: 48rem;
  }
  .text-img-grid{
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 72rem;
    .text-block{
      padding: 66rem 48rem;
      background-color: $bg-green;
      border-bottom-left-radius: 24rem;
      border-top-left-radius: 24rem;
    }
    .img-block{
      background-image: url(../img/nature1.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-top-right-radius: 24rem;
      border-bottom-right-radius: 24rem;
    }
  }
  .page-title{
    @include fnt(40, 48, white, 600);
    &._green{
      color: $main-dark;
    }
  }
  .page-text{
    @include fnt(14, 24, white, 400);
    border-bottom: 1px solid #107569;
  }
  .gallery-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 118rem;
    grid-gap: 24rem;
    .gallery-img{
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      aspect-ratio: 1/1;
      border-radius: 16rem;
      &._1{
        background-image: url(../img/nature2.jpg);
      }
      &._2{
        background-image: url(../img/nature3.jpg);
      }
      &._3{
        background-image: url(../img/nature4.jpg);
      }
    }
    margin-bottom: 118rem;
  }
  .booking{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #134E48;
    padding: 24rem;
    border-radius: 24rem 24rem 0 0;
    max-width: 1008rem;
    //aspect-ratio: 1008/108;
    width: 100%;
    align-self: center;

    &-title{
      @include fnt(24, 32, white, 600);
    }
    &-subtitle{
      @include fnt(14, 24, white, 400);
      border-radius: 8px;
      background-color: #2ED3B7;
      padding: 10rem 24rem;
      width: -moz-fit-content;
      width: fit-content;
    }
  }
  .form-block{
    padding: 64rem;
    width: 100%;
    max-width: 1088rem;
    background-color: $light;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 24px;
    background-image: url(../img/Circles_white.svg);
    background-repeat: no-repeat;
    background-position: right bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    z-index: 5;
    .form-title{
      @include fnt(32, 48, $black, 600);
    }
    .form-subtitle{
      @include fnt(16, 24, $text-gray, 400);
    }
    .consultation-form{
      margin-bottom: 24rem;
      .phone-input{
        height: auto;
        border: 1px solid rgba(0, 16, 61, 0.12);
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 9rem 11rem;
        @include fnt(16, 24, $text-gray, 400);
        background-color: white;
        cursor: pointer;
        width: 320rem;
        margin-right: 16rem;
      }
      .send-form_btn{
        @include fnt(16, 24, white, 600);
        border-radius: 8px;
        background-color: $main;
        padding: 10rem 24rem;
        width: fit-content;
        &:hover{
          cursor: pointer;
        }
      }
    }
    .agreement-text{
      @include fnt(12, 16, $text-gray, 400);
    }
  }
  .personal-data-link{
    @include fnt(12, 16, $text-gray, 400);
    &:hover{
      color: $text-gray;
      cursor: pointer;
    }
  }
  .success-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    .success-message_title{
      @include fnt(32, 48, $black, 600);
      display: flex;
      flex-direction: column;
      align-items: center;
      &:before{
        content: '';
        background-image: url(../img/check.svg);
        background-size: 48rem;
        background-repeat: no-repeat;
        background-position: center;
        @include size(48);
        display: block;
      }
    }
    .success-message_subtitle{
      @include fnt(16, 24, $text-gray, 400);
    }
  }
}
.footer{
  display: flex;
  flex-direction: column;
  .top{
    background-color: $bg-green;
    padding: 20rem 24rem;
    .block-content{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .bottom{
    background-color: $main-dark;
    padding: 4rem 24rem;
    p{
      @include fnt(10, 16, white, 400);
    }
  }
  .bik-link{
    @include fnt(12, 24, white, 400);
    padding: 0 12rem;
    border: 1px solid #FFFFFF;
    border-radius: 16rem;
  }
  .phone{
    @include fnt(20, 24, white, 400);
    margin-right: 16rem;
  }
  .bik-text{
    @include fnt(12, 12, white, 400);
    margin-left: 12rem;
    max-width: 137rem;
  }

}
.yandex-map{
  margin-top: -46rem;
}
.btn{
  padding: 10rem 16rem;
  border-radius: 8px;
  background-color: $main;
  @include fnt(14, 24, white, 400);
  display: flex;
  width: fit-content;
  &._download{
    &:before{
      content: '';
      background-image: url(../img/download.svg);
      background-size: 20rem;
      background-repeat: no-repeat;
      background-position: center;
      @include size(20);
      display: block;
      margin-right: 4rem;
    }
  }
  &:hover{
    color: white;
  }
}
.bold{
  font-weight: 600!important;
}
.iframe-ya{
  pointer-events: unset;
}