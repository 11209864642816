@import "_variables";

html {
  font-size: 1.2px;
  scroll-behavior: smooth;
}
@media screen and (max-width: 1440px) {
  html {
    font-size: calc((100 * 100vw / 1440 / 100));
  }
}
@media screen and (max-width: 1023px) {
  html {
    font-size: calc((100 * 100vw / 1024 / 100));
  }
}
@media screen and (max-width: 800px) {
  html {
    font-size: calc((100 * 100vw / 800 / 100));
  }
}
@media screen and (max-width: 600px) {
  html {
    font-size: calc((100 * 100vw / 600 / 100));
  }
}
@media screen and (max-width: 400px) {
  html {
    font-size: calc((100 * 100vw / 375 / 100));
  }
}

body {
  display: block;
  width: 100%;
  font-size: 16rem;
  line-height: 24rem;
  margin: 0;
}


$indents: 0, 4, 8, 12, 16, 24, 32, 48, 54;
.m {
  @each $margin in $indents {
    &-#{$margin} {
      margin: #{$margin}rem !important;
    }
    &t-#{$margin} {
      margin-top: #{$margin}rem !important;
    }
    &b-#{$margin} {
      margin-bottom: #{$margin}rem !important;
    }
    &l-#{$margin} {
      margin-left: #{$margin}rem !important;
    }
    &r-#{$margin} {
      margin-right: #{$margin}rem !important;
    }
    &y-#{$margin} {
      margin-top: #{$margin}rem !important;
      margin-bottom: #{$margin}rem !important;
    }
    &x-#{$margin} {
      margin-right: #{$margin}rem !important;
      margin-left: #{$margin}rem !important;
    }
  }
}
.p {
  @each $padding in $indents {
    &-#{$padding} {
      padding: #{$padding}rem !important;
    }
    &t-#{$padding} {
      padding-top: #{$padding}rem !important;
    }
    &b-#{$padding} {
      padding-bottom: #{$padding}rem !important;
    }
    &l-#{$padding} {
      padding-left: #{$padding}rem !important;
    }
    &r-#{$padding} {
      padding-right: #{$padding}rem !important;
    }
    &y-#{$padding} {
      padding-top: #{$padding}rem !important;
      padding-bottom: #{$padding}rem !important;
    }
    &x-#{$padding} {
      padding-right: #{$padding}rem !important;
      padding-left: #{$padding}rem !important;
    }
  }
}

.d-none {
  display: none!important;
}
.d-flex {
  display: flex!important;
}
.justify-space-between{
  justify-content: space-between;
}
.align-items-center{
  align-items: center;
}

h1{
  @include fnt(120, 120, white, 600);
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 0px 32px #92C8DA;
}
